import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";

import { extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";
import store from "./Store/store";

const theme = extendTheme({
  colors: {
    black: "#000",
    green: {
      50: "#e6f7e6",
      100: "#b3e6b3",
      200: "#80d680",
      300: "#4dcc4d",
      400: "#26b326",
      500: "#1a8a1a",
      600: "#146614",
      700: "#0f4f0f",
      800: "#0a3a0a",
      900: "#052205",
    },
  },
  components: {
    Button: {
      baseStyle: {
        bg: "black",
        color: "green.600",
        _hover: {
          bg: "orange",
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9))",
        color: "green.500",
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <Router>
          <AppRoutes />
        </Router>
      </ChakraProvider>
    </React.StrictMode>
  </Provider>
);
