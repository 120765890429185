import React, { useState, useCallback } from "react";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { FaHome, FaUser, FaSignOutAlt, FaUsers } from "react-icons/fa";
import { routes } from "../../Routes/routers";
import { useNavigate, useLocation } from "react-router-dom";

export const BottomNavigation: React.FC = () => {
  const [activeButton, setActiveButton] = useState<string>(routes.home);
  const navigate = useNavigate();
  const location = useLocation();

  const navigationButtons = [
    { route: routes.home, icon: FaHome, label: "Home" },
    { route: routes.perfil, icon: FaUser, label: "Profile" },
    { route: routes.team, icon: FaUsers, label: "Team" },
    { route: routes.login, icon: FaSignOutAlt, label: "Logout" },
  ];

  React.useEffect(() => {
    setActiveButton(location.pathname);
  }, [location]);

  const selectHandle = useCallback(
    (menu: string) => {
      setActiveButton(menu);
      navigate(menu);
    },
    [navigate]
  );

  const handleBottomNavigation = useCallback(() => {
    return navigationButtons.map(({ route, icon: Icon, label }) => (
      <IconButton
        key={route}
        icon={<Icon />}
        aria-label={label}
        colorScheme={activeButton === route ? "green" : "gray"}
        variant={activeButton === route ? "solid" : "ghost"}
        fontSize="xl"
        onClick={() => selectHandle(route)}
      />
    ));
  }, [activeButton, selectHandle]);

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      bg="gray.800"
      boxShadow="md"
      zIndex={1000}
    >
      <HStack justify="space-around" py={3} spacing={4}>
        {handleBottomNavigation()}
      </HStack>
    </Box>
  );
};
