import {
  Box,
  SimpleGrid,
  Skeleton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { usePlayerStory } from "./usePlayerHistory";
import { PlayerEdit } from "../../Components/PlayerEdit";
import Layout from "../../Components/Layout";
import {
  ObjectiveData,
  RegisterObjectiveModal,
} from "../../Components/RegisterObjectiveModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store/types";
import { useSelector } from "react-redux";
import { updateGoal } from "../../Services/objective";

export const PlayerHistory: React.FC = () => {
  const toast = useToast();
  const [objectiveData, setObjectiveData] = useState<ObjectiveData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { history, loading, setLoading, handleLoadPlayerHistory } =
    usePlayerStory();

  const { user } = useSelector((state: RootState) => state);
  const { userSelected } = user;

  const handleEdit = async (data: ObjectiveData) => {
    setObjectiveData({
      ...data,
      seasonId: userSelected?.season || 0,
      userId: userSelected?.id || 0,
    });
    onOpen();
  };

  const handleRegisterObjective = async (data: ObjectiveData) => {
    setLoading(true);
    console.warn({ data });
    const reponse = await updateGoal(
      data.goal,
      data.victory,
      data.date,
      data.userId,
      data.seasonId,
      data.id || 0
    );
    if (!reponse) {
      toast({
        title: "Erro",
        description: "Ocorreu um erro ao atualizar.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (reponse) {
      await handleLoadPlayerHistory();
    }

    setLoading(false);
  };

  return (
    <Layout title={userSelected?.name} onBack={() => navigate(-1)}>
      <RegisterObjectiveModal
        key={objectiveData?.userId}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleRegisterObjective}
        seasonId={userSelected?.season || 0}
        userId={userSelected?.id || 0}
        name={userSelected?.name || ""}
        date={objectiveData?.date}
        goal={objectiveData?.goal}
        victory={objectiveData?.victory}
        id={objectiveData?.id}
        idEdit
      />

      <Box
        display="flex"
        flexDirection="column"
        minH="100vh"
        p={0}
        overflow="hidden"
      >
        <Box
          flex="1"
          maxW="6xl"
          w="full"
          p={4}
          borderRadius="md"
          boxShadow="lg"
          overflowY="auto"
        >
          {loading && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {[...Array(6)].map((_, index) => (
                <Skeleton key={index} height="150px" borderRadius="md" />
              ))}
            </SimpleGrid>
          )}

          {!loading && history?.goals && history?.goals.length > 0 && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {history?.goals?.map((player) => (
                <Box
                  key={player.user_id}
                  borderRadius="md"
                  boxShadow="md"
                  p={4}
                >
                  <PlayerEdit
                    key={player.id}
                    goal={player.goal}
                    victory={player.victory}
                    date={player.date}
                    edit={handleEdit}
                    playerId={userSelected?.id || 0}
                    seasonId={userSelected?.season || 0}
                    id={player.id}
                    isAdmin={!!(user.user?.admin ?? false)}
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Box>
    </Layout>
  );
};
