import { useEffect, useState } from "react";
import { getPlayersBySeason, SeasonUsersResponse } from "../../Services/home";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/types";

export const usePlayers = () => {
  const [players, setPlayers] = useState<SeasonUsersResponse>();
  const [loading, setLoading] = useState(false);

  const { season } = useSelector((state: RootState) => state);

  const handleGetPlayers = async () => {
    setLoading(true);
    const response = await getPlayersBySeason(season.data?.id ?? 0);
    setPlayers(response);
    setLoading(false);
  };

  useEffect(() => {
    if (season.data?.id) {
      handleGetPlayers();
    }
  }, [season]);

  return {
    players,
    loading,
    setLoading,
  };
};
