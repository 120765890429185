import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SeasonData {
  id?: number;
  name?: string;
  start?: string;
  end?: string | null;
}

export interface SeasonState {
  data: SeasonData | null;
}

const initialState: SeasonState = {
  data: null,
};

const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {
    setSeason(state, action: PayloadAction<SeasonData>) {
      state.data = action.payload;
    },
    clearSeason(state) {
      state.data = null;
    },
  },
});

export const { setSeason, clearSeason } = seasonSlice.actions;

export default seasonSlice.reducer;
