import React from "react";
import { Box, Image, Text, HStack, VStack } from "@chakra-ui/react";

interface PlayerCardProps {
  photoUrl: string;
  name: string;
  goals: number;
  textGoal: string;
}

export const PlayerCard: React.FC<PlayerCardProps> = ({
  photoUrl,
  name,
  goals,
  textGoal,
}) => {
  return (
    <Box
      w="100%"
      p={2}
      borderRadius="md"
      boxShadow="md"
      bg="white"
      textAlign="center"
    >
      <HStack spacing={4} align="center">
        <Image
          borderRadius="full"
          boxSize="50px"
          src={photoUrl}
          alt={`${name} photo`}
        />
        <VStack align="start" spacing={1}>
          <Text fontSize="xl" fontWeight="bold">
            {name}
          </Text>
          <Text fontSize="md" color="gray.900">
            {textGoal}: {goals}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};
