import axios from "axios";
import { refreshToken } from "./login";

const api = axios.create({
  // baseURL: "http://localhost/api",
  baseURL: "https://lorotafutebol.click/api",
  withCredentials: true,
  headers: {
    accept: "application/json",
  },
});

axios.defaults.withCredentials = true;

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      window.location.pathname !== "/"
    ) {
      originalRequest._retry = true;
      try {
        const response = await refreshToken();
        const newToken = response.token;

        localStorage.setItem("jwt_token", newToken);

        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        return api(originalRequest);
      } catch (refreshError) {
        console.error(
          "Refresh token falhou. Redirecionando para login.",
          refreshError
        );
        localStorage.removeItem("jwt_token");

        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
