import api from "./api";

export interface FileData {
  id: number;
  data: string;
  file: string;
  created_at: string;
  updated_at: string;
  season_id: number;
}

export const getFileData = async (
  seasonId: number
): Promise<FileData[] | undefined> => {
  try {
    const response = await api.get<FileData[]>(
      `/file-seasons/season/${seasonId}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar arquivos da temporada:", error);
    return undefined;
  }
};

export const getFile = async (fileId: number): Promise<Blob | undefined> => {
  try {
    const response = await api.get<Blob>(`/file-seasons/${fileId}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar o arquivo:", error);
    return undefined;
  }
};

export const sendFile = async (
  selectedFile: File,
  uploadDate: string,
  seasonId: number
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append("data", uploadDate);
    formData.append("photo", selectedFile);
    formData.append("season_id", seasonId.toString());

    await api.post("/file-seasons", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return true;
  } catch (error) {
    console.error("Erro ao enviar o arquivo:", error);
    return false;
  }
};

export const deleteFile = async (id: number): Promise<boolean> => {
  try {
    await api.delete(`/file-seasons/${id}`);
    return true;
  } catch (error) {
    console.error("Erro ao delete arquivo da temporada:", error);
    return false;
  }
};
