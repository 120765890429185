import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Button,
  Input,
  useToast,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import Layout from "../../Components/Layout";
import { usePlay } from "./usePlayer";
import { RootState } from "../../Store/types";
import { getRecords, PlayerStats, updateUserPhoto } from "../../Services/home";
import { setUserDetails } from "../../Store/features/userSlice";

export const Player: React.FC = () => {
  const { handleGetPlay, loading, setLoading } = usePlay();
  const toast = useToast();

  const { goal_count, total_goals, user } = useSelector(
    (state: RootState) => state.user
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordData, setRecordData] = useState<PlayerStats>();

  const openModalWithRecords = async () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [photoUrl, setPhotoUrl] = useState(user?.photo || "");

  const isBase64Image = (url: string) => {
    return /^data:image\/(png|jpeg|jpg|gif|webp);base64,/.test(url);
  };

  const displayPhotoUrl = isBase64Image(photoUrl) ? photoUrl : "/logo192.png";

  const handleGetStatus = async (userid: number) => {
    const response = await getRecords(userid);
    if (response) {
      const data = {
        user_name: response?.user_name,
        max_goals: response?.max_goals,
        average_goals: response?.average_goals,
        matches_played: response?.matches_played,
      };
      setRecordData(data);
    }
  };

  useEffect(() => {
    handleGetPlay();
  }, []);

  useEffect(() => {
    if (user?.id) {
      handleGetStatus(user.id);
    }
  }, [user]);

  const updatePhotoUrl = useCallback(() => {
    setPhotoUrl(user?.photo || "");
  }, [user]);

  useEffect(() => {
    updatePhotoUrl();
  }, [user, updatePhotoUrl]);

  const handlePhotoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        toast({
          title: "Erro",
          description:
            "Tipo de arquivo inválido. Por favor, envie uma imagem JPEG ou PNG.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const maxSizeInMB = 5;
      if (file.size > maxSizeInMB * 1024 * 1024) {
        toast({
          title: "Erro",
          description: `A imagem não pode ultrapassar ${maxSizeInMB}MB.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.result) {
          const base64String = reader.result as string;

          try {
            setLoading(true);
            await updateUserPhoto(user?.id || 0, file);
            setUserDetails({
              user: { ...user, photo: base64String },
              goal_count,
              total_goals,
            });
            setPhotoUrl(base64String);
          } catch (err) {
            toast({
              title: "Erro",
              description: "Erro ao atualizar a foto",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } finally {
            setLoading(false);
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Layout title="Suas informações">
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Estatísticas do Jogador</ModalHeader>
          <ModalBody>
            <Text>
              <b>Nome:</b> {recordData?.user_name}
            </Text>
            <Text>
              <b>Máximo de Gols:</b> {recordData?.max_goals}
            </Text>
            <Text>
              <b>Média de Gols:</b> {recordData?.average_goals}
            </Text>
            <Text>
              <b>Partidas Jogadas:</b> {recordData?.matches_played}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={closeModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {loading ? (
          <>
            <SkeletonCircle boxSize={{ base: "300px", md: "400px" }} mb={6} />
            <VStack spacing={4} textAlign="center" width="100%">
              <Skeleton height="30px" width="60%" />
              <Skeleton height="20px" width="50%" />
              <Skeleton height="20px" width="50%" />
              <Skeleton height="40px" width="40%" />
            </VStack>
          </>
        ) : (
          <>
            <Text fontSize="3xl" fontWeight="bold" color="green.700">
              {user?.name}
            </Text>
            <Image
              src={displayPhotoUrl}
              alt={user?.name || "Usuário"}
              borderRadius="50%"
              boxSize={{ base: "300px", md: "400px" }}
              objectFit="cover"
              mt={6}
            />
            <VStack spacing={4} textAlign="center" mt={6}>
              <Text fontSize="lg" color="green.500">
                Gols Marcados: {total_goals}
              </Text>
              <Text fontSize="lg" color="green.500">
                Partidas Jogadas: {goal_count}
              </Text>
              <Box>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                  display="none"
                  id="photo-upload"
                />
                <Button
                  as="label"
                  htmlFor="photo-upload"
                  colorScheme="teal"
                  mt={4}
                >
                  Alterar foto
                </Button>
              </Box>

              <Button colorScheme="green" mt={4} onClick={openModalWithRecords}>
                Recordes
              </Button>
            </VStack>
          </>
        )}
      </Box>
    </Layout>
  );
};
