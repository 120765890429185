import React from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { ObjectiveData } from "../RegisterObjectiveModal";

interface PlayerInfoInterface {
  goal: number;
  victory: number;
  playerId: number;
  edit: (data: ObjectiveData) => void;
  seasonId: number;
  date: string;
  id: number;
  isAdmin?: boolean;
}

export const PlayerEdit: React.FC<PlayerInfoInterface> = ({
  playerId,
  edit,
  seasonId,
  goal,
  victory,
  date,
  id,
  isAdmin = false, // Valor padrão
}) => {
  const data: ObjectiveData = {
    userId: playerId,
    seasonId,
    goal,
    victory,
    date,
    id,
  };

  const navigationButtons = [
    {
      icon: FaEdit,
      label: "Edit",
      action: () => edit(data),
    },
  ];

  const formatDateToBR = (date: string) => {
    const parsedDate = new Date(date);
    return new Intl.DateTimeFormat("pt-BR").format(parsedDate);
  };

  return (
    <Box
      w="100%"
      p={2}
      borderRadius="md"
      boxShadow="md"
      bg="white"
      textAlign="center"
    >
      <HStack spacing={4} align="center">
        <VStack align="start" spacing={1} flex="1">
          <Text fontSize="xl" fontWeight="bold">
            Gols: {goal}
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            Vitória: {victory}
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            Data: {formatDateToBR(date)}
          </Text>
        </VStack>
        <HStack spacing={2}>
          {isAdmin &&
            navigationButtons.map((button, index) => (
              <Tooltip key={index} label={button.label}>
                <IconButton
                  aria-label={button.label}
                  icon={<button.icon />}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    button.action();
                  }}
                />
              </Tooltip>
            ))}
        </HStack>
      </HStack>
    </Box>
  );
};
