import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useLoginScreen } from "./userLoginScreen";
import { newPlayer } from "../../Services/home";
import { useEffect, useState } from "react";

export const LoginScreen: React.FC = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  type BeforeInstallPromptEvent = Event & {
    prompt: () => void;
    userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
  };

  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const {
    playerName,
    cellPhone,
    password,
    playerLogin,
    playerPassword,
    handleCellPhoneChange,
    handlePasswordChange,
    goToHome,
    handlePlayerName,
    handlePlayerLogin,
    handlePlayerPassword,
  } = useLoginScreen();

  const handleLogin = () => {
    if (cellPhone && password) {
      goToHome();
    } else {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewUser = async () => {
    if (playerPassword && playerLogin && playerName) {
      await newPlayer(playerLogin, playerPassword, playerName);
      onClose();
      toast({
        title: "Success",
        description: `Olá ${playerName}`,
        status: "success",
        duration: 6000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("Usuário aceitou instalar o PWA");
      } else {
        console.log("Usuário recusou instalar o PWA");
      }
      setDeferredPrompt(null);
      setIsVisible(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minH="100vh"
      p={4}
    >
      <Box maxW="md" w="full" p={6} borderRadius="md" boxShadow="lg" bg="white">
        <Heading as="h1" size="lg" textAlign="center">
          Login
        </Heading>
        <Stack spacing={4}>
          <FormControl id="email" isRequired>
            <FormLabel>Login</FormLabel>
            <Input
              value={cellPhone}
              type="email"
              placeholder="85981804597"
              onChange={handleCellPhoneChange}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Senha</FormLabel>
            <Input
              value={password}
              type="password"
              placeholder="***"
              onChange={handlePasswordChange}
            />
          </FormControl>
          <Button
            mt={"12"}
            size="lg"
            onClick={handleLogin}
            isFullWidth
            variant="ghost"
          >
            Entrar
          </Button>
          <Button mt={"4"} size="lg" onClick={onOpen} isFullWidth>
            Inscrever-se
          </Button>
          <Button mt={"4"} size="lg" onClick={handleInstallClick} isFullWidth>
            Instalar o app
          </Button>
        </Stack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inscrever-se</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="signup-name" isRequired>
                <FormLabel>Nome</FormLabel>
                <Input placeholder="" type="text" onChange={handlePlayerName} />
              </FormControl>
              <FormControl id="signup-login" isRequired>
                <FormLabel>Login</FormLabel>
                <Input
                  placeholder=""
                  type="text"
                  onChange={handlePlayerLogin}
                />
              </FormControl>
              <FormControl id="signup-password" isRequired>
                <FormLabel>Senha</FormLabel>
                <Input
                  placeholder="***"
                  type="password"
                  onChange={handlePlayerPassword}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleNewUser} variant="ghost">
              Criar Conta
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
