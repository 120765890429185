import api from "./api";

interface LoginResponse {
  message: string;
  token: string;
}

export const login = async (
  login: string,
  password: string
): Promise<LoginResponse> => {
  const response = await api.post("/login", { login, password });
  return response.data;
};

export const refreshToken = async (): Promise<LoginResponse> => {
  const response = await api.post("/refresh-token");
  return response.data;
};
