import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../Pages/Home";
import { Player } from "../Pages/Player";
import { LoginScreen } from "../Pages/Login";
import { routes } from "./routers";
import { Players } from "../Pages/Players";
import { PlayerHistory } from "../Pages/PlayerHistory";
import { File } from "../Pages/File";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<LoginScreen />} />
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.perfil} element={<Player />} />
      <Route path={routes.team} element={<Players />} />
      <Route path={routes.playerHistory} element={<PlayerHistory />} />
      <Route path={routes.files} element={<File />} />
    </Routes>
  );
};

export default AppRoutes;
