import { useEffect, useState } from "react";
import { getPlayersHistory, PlayerHistoryResponse } from "../../Services/home";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";

export const usePlayerStory = () => {
  const [history, setHistory] = useState<PlayerHistoryResponse>();
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state: RootState) => state);
  const { userSelected } = user;

  const handleLoadPlayerHistory = async () => {
    setLoading(true);
    const result = await getPlayersHistory(
      userSelected?.season || 0,
      userSelected?.id || 0
    );
    setHistory(result);
    setLoading(false);
  };

  useEffect(() => {
    if (userSelected?.id && userSelected?.season) {
      handleLoadPlayerHistory();
    }
  }, [userSelected]);

  return {
    history,
    loading,
    setLoading,
    handleLoadPlayerHistory,
  };
};
