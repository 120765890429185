import { Box, SimpleGrid, Skeleton, Text } from "@chakra-ui/react";
import { PlayerCard } from "../../Components/PlayerCard";
import Layout from "../../Components/Layout";
import { useHome } from "./userHome";

export const Home: React.FC = () => {
  const { error, loading, players } = useHome();

  return (
    <Layout title="Ranking por Gols">
      <Box
        display="flex"
        flexDirection="column"
        minH="100vh"
        p={0}
        overflow="hidden"
      >
        <Box
          flex="1"
          maxW="6xl"
          w="full"
          p={4}
          borderRadius="md"
          boxShadow="lg"
          overflowY="auto"
        >
          {loading && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {[...Array(6)].map((_, index) => (
                <Skeleton key={index} height="150px" borderRadius="md" />
              ))}
            </SimpleGrid>
          )}

          {!loading && !error && players?.data && players.data.length > 0 && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {players?.data?.map((player) => (
                <Box
                  key={player.user_id}
                  borderRadius="md"
                  boxShadow="md"
                  p={4}
                >
                  <PlayerCard
                    name={player?.user_name}
                    photoUrl={player?.user_photo}
                    goals={parseInt(player?.total_goals)}
                    textGoal="Gols"
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}

          {!loading && !error && players?.data?.length === 0 && (
            <Box textAlign="center" mt={4}>
              <Text fontWeight="bold">Nenhum jogador encontrado.</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
};
