import React, { useEffect } from "react";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { BottomNavigation } from "../BottomNavigation";
import { getFirstActiveSeason } from "../../Services/season";
import { useDispatch } from "react-redux";
import { setSeason } from "../../Store/features/seasonSlice";
import { getPlayersBySeasonToken } from "../../Services/home";
import { setUserDetails } from "../../Store/features/userSlice";
import { CiSettings } from "react-icons/ci";
import { MdAddToPhotos } from "react-icons/md";

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  onBack?: () => void;
  onClick?: () => void;
  add?: () => void;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  onBack,
  onClick,
  add,
}) => {
  const dispatch = useDispatch();

  const handleLoadData = async () => {
    const responseSeasonActive = await getFirstActiveSeason();
    if (responseSeasonActive) {
      dispatch(
        setSeason({
          start: responseSeasonActive.data?.start,
          end: null,
          id: responseSeasonActive.data?.id,
          name: responseSeasonActive.data?.name,
        })
      );
      const response = await getPlayersBySeasonToken(
        responseSeasonActive.data?.id || 0
      );

      if (response) {
        dispatch(
          setUserDetails({
            user: response.user,
            total_goals: response.total_goals,
            goal_count: response.goal_count,
          })
        );
      }
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <Box display="flex" flexDirection="column" minH="100vh" overflow="hidden">
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        bg="gray.800"
        boxShadow="md"
        zIndex={1000}
      >
        <Flex align="center" justify="space-between" py={3} px={4}>
          {onBack ? (
            <IconButton
              aria-label="Voltar"
              icon={<MdArrowBack />}
              onClick={onBack}
              colorScheme={"green"}
              variant={"solid"}
            />
          ) : (
            <Box width="40px" />
          )}
          {title && (
            <Text textAlign="center" flex="1" fontWeight="bold" fontSize="lg">
              {title}
            </Text>
          )}
          {onClick ? (
            <IconButton
              aria-label="Configuracoes"
              icon={<CiSettings />}
              onClick={onClick}
              colorScheme={"green"}
              variant={"solid"}
            />
          ) : (
            <Box width="40px" />
          )}

          {add && (
            <IconButton
              aria-label="Novo"
              icon={<MdAddToPhotos />}
              onClick={add}
              colorScheme={"green"}
              variant={"solid"}
            />
          )}
        </Flex>
      </Box>

      {/* Conteúdo rolável */}
      <Box
        flex="1"
        mt="64px" // Altura do cabeçalho
        mb="64px" // Altura do BottomNavigation
        overflowY="auto"
      >
        {children}
      </Box>

      {/* BottomNavigation fixo */}
      <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        bg="gray.800"
        zIndex={1000}
      >
        <BottomNavigation />
      </Box>
    </Box>
  );
};

export default Layout;
