import api from "./api";

export interface ActiveSeasonResponse {
  message: string;
  data: ActiveSeason | null;
}

export interface ActiveSeason {
  id: number;
  name: string;
  start: string;
  end: string | null;
}

export const getFirstActiveSeason = async (): Promise<
  ActiveSeasonResponse | undefined
> => {
  try {
    const response = await api.get<ActiveSeasonResponse>("/seasons/active");
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar a temporada ativa:", error);
    return undefined;
  }
};
