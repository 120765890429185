import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { getPlayers, TopScorersResponse } from "../../Services/home";
import { RootState } from "../../Store/store";

export const useHome = () => {
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState<TopScorersResponse>();
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  const { season } = useSelector((state: RootState) => state);

  const toast = useToast();

  const handleGetPlayers = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getPlayers(season.data?.id || 0);
      setPlayers(response);
      setRetryCount(0);
    } catch (err) {
      if (retryCount < 1) {
        setRetryCount(retryCount + 1);
        handleGetPlayers();
      } else {
        setError("Ocorreu um erro ao buscar os jogadores.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (season.data?.id) {
      handleGetPlayers();
    }
  }, [season]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Erro",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  return {
    loading,
    players,
    error,
  };
};
