import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  id?: number;
  name?: string;
  login?: string;
  admin?: number;
  photo?: string;
  season?: number;
}

export interface UserState {
  user: User | null;
  total_goals: string | null;
  goal_count: number | null;
  userSelected: User | null;
}

const initialState: UserState = {
  user: null,
  total_goals: null,
  goal_count: null,
  userSelected: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setTotalGoals(state, action: PayloadAction<string>) {
      state.total_goals = action.payload;
    },
    setGoalCount(state, action: PayloadAction<number>) {
      state.goal_count = action.payload;
    },
    setUserDetails(
      state,
      action: PayloadAction<{
        user: User | null;
        total_goals: string | null;
        goal_count: number | null;
      }>
    ) {
      state.user = action.payload.user;
      state.total_goals = action.payload.total_goals;
      state.goal_count = action.payload.goal_count;
    },
    setUserSelected(state, action: PayloadAction<User>) {
      state.userSelected = action.payload;
    },
  },
});

export const {
  setUser,
  setTotalGoals,
  setGoalCount,
  setUserDetails,
  setUserSelected,
} = userSlice.actions;
export default userSlice.reducer;
